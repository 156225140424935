import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function PowerBIReport() {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("isloggedin") !== "yes") {
      navigate("/admin-login");
    }
  });

  return (
    <div style={{ height: "100vh" }}>
      <iframe
        title="JobMohostav"
        width="100%"
        height="100%"
        frameborder="0"
        src="https://app.powerbi.com/view?r=eyJrIjoiZWE3MjIwMTUtZWU0MC00ZWNkLWI4YzEtZmVjNzA2MjMzODAxIiwidCI6ImQzODYzNGE4LTMwNGUtNDIyNy04ZWNkLTA3ZDk1OWYyNjljYSJ9"
        allowFullScreen="true"
      ></iframe>
    </div>
  );
}

export default PowerBIReport;
