import JobMohatsav from "./Pages/JobMohatsav";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import PowerBIReport from "./Pages/Powerbi"; 
import PowerBIReportUser from "./Pages/powerbiUser";
import ViewData from "./Pages/ViewData";
import CompanyLogin from "./Pages/CompanyLogin";
import CompanyAttendance from "./Pages/CompanyAttendance";
import FloorLogin from "./Pages/FloorLogin"
import FloorDashboard from "./Pages/FloorDashboard"
import Announcement from "./Pages/Announcement";
import FeedbackForm from "./Pages/FeedbackForm";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<JobMohatsav />} />
          <Route path="/admin-login" element={<Login />} />     
          <Route path="/report-admin" element={<PowerBIReport />} />
          <Route path="/report-user" element={<PowerBIReportUser />} />
          <Route path="/view-data" element={<ViewData />} />         
          <Route path="/company-login" element={<CompanyLogin />} />
          <Route path="/company-dashboard" element={<CompanyAttendance />} />
          <Route path="/Floor-login" element={<FloorLogin />} />
          <Route path="/Floor-Dashboard" element={<FloorDashboard />} />
          <Route path="/Annoucement" element={<Announcement />} />
          <Route path="/Feedback" element={<FeedbackForm />} />
          
        

        </Routes>
      </div>
    </Router>
  );
}

export default App;
