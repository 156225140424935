import React, { useState, useRef } from "react";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import "../Styles/JobMohatsav.css";
import "../Styles/Career.css";
import Swal from "sweetalert2";
import SP from "../Images/S P.png";
import RP from "../Images/R P.png";
import Slide1 from "../Images/Slide1.png";
import Slide2 from "../Images/Slide2.png";
import Slide3 from "../Images/Slide3.png";
import Slide4 from "../Images/Slide4.png";
import Underline from "../Images/Rectangle 61.png";
import img1 from "../Images/image 1.png";
import Carousel from "react-bootstrap/Carousel";
import axios from "axios";
import Marquee from "react-fast-marquee";
import Nasscom from "../Images/nasscom.png"
import Future from "../Images/futureskill.png"

export default function JobMohatsav() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [resume, setResume] = useState("");
  const [Address, setAddress] = useState("");
  const [AdharNumber, setAdharNumber] = useState("");
  const [selectedQualification, setSelectedQualification] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedVillage, setSelectedVillage] = useState("");

  const resumeInputRef = useRef(null);

  const formRef = useRef(null);

  const handleFileUpload = (event) => {
    console.log(event.target.files[0]);
    setResume(event.target.files[0]);
  };

  const showSwalAlertfalse = (message) => {
    Swal.fire({
      // text: message,
      title: message,
      icon: "info",
      confirmButtonText: "OK",
    }).then((result) => {});
  };
  const showSwalAlert = () => {
    Swal.fire({
      title: "Congratulations !!!",
      text: "We have received your application and delighted to invite you for the Kolhapur Dakshin Job Mahotsav 2023. Our team will reach out to you to schedule your interview with prospective employers shortly.",
      icon: "success",
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed) {
        setFullName("");
        setEmail("");
        setMobileNumber("");
        setAddress("");
        setAdharNumber("");
        setSelectedQualification("");
        setSpecialization("");
        setSelectedRegion("");
        setSelectedVillage("");

        if (resumeInputRef.current) {
          resumeInputRef.current.value = "";
        }
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submit button clicked");

    var formData = new FormData();
    formData.append("senddata", "yes");
    formData.append("fullName", fullName);
    formData.append("email", email);
    formData.append("mobileNumber", mobileNumber);
    formData.append("Address", Address);
    formData.append("AdharNumber", AdharNumber);
    formData.append("selectedQualification", selectedQualification);
    formData.append("selectedRegion", selectedRegion);
    formData.append("selectedVillage", selectedVillage);
    formData.append("resume", resume);
    formData.append("specialization", specialization);
    console.log(formData);
    try {
      const response = await axios.post(
        "https://kolhapurdakshin.com/Jobmohatsav/jobmohotsav.php",
        formData
      );

      if (response.data.success === false) {
        // Swal.fire("Success", "Form data submitted successfully!", "success");
        console.log("Form submission failed");
        showSwalAlertfalse(response.data.message);
      } else {
        // Swal.fire("Error", "Form submission failed.", "error");
        console.log("Form data submitted successfully");
        showSwalAlert();
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const regions = ["North", "South", "SouthRural"];

  const northVillages = [
    "1-शुगर मिल",
    "2-कसबा बावडा पूर्व बाजू",
    "3-कसबा बावडा हनुमान तलाव",
    "4-कसबा बावडा लाईन बझार",
    "5-लक्ष्मी विलास पँलेस",
    "6-पोलीस लाईन",
    "7-सर्किट हाउस",
    "8-भोसलेवाडी-कदमवाडी",
    "9-कदमवाडी",
    "10-शाहू कॉलेज",
    "11-ताराबाई पार्क",
    "12-नागाळा पार्क",
    "13-रमणमळा",
    "14-व्हीनस कॉर्नर",
    "15-कनान नगर",
    "16-शिवाजी पार्क",
    "17-सदर बाजार",
    "18-महाडिक वसाहत",
    "19-मुक्त-सैनिक वसाहत",
    "23-रुईकर कॉलनी",
    "24-साईक्स एक्सटेन्शन",
    "25-शाहूपुरी तालीम",
    "26-कॉमर्स कॉलेज",
    "27-ट्रेझरी ऑफिस",
    "28-सिद्धार्थ नगर",
    "29-शिपुगडे तालीम",
    "30-खोलखंडोबा",
    "31-बाजार गेट",
    "32-बिंदू चौक",
    "33-महालक्ष्मी मंदिर",
    "34-शिवाजी उद्यमनगर",
    "35-यादवनगर",
    "36-राजारामपुरी",
    "37-राजारामपुरी-तवनाप्पा पाटणे हायस्कूल",
    "38-टाकाळा खाण माळी कॉलनी",
    "39-राजारामपुरी एक्स्टेन्शन",
    "42-पांजरपोळ",
    "43-शास्त्रीनगर - जवाहरनगर",
    "44-मंगेशकर नगर",
    "45-कैलासगडची स्वारी मंदिर",
    "46-सिद्धाळा गार्डन",
    "47-फिरंगाई",
    "48-तटाकडील तालीम",
    "49-रंकाळा स्टँड",
    "50-पंचगंगा तालीम",
    "53-दुधाळी पँव्हेलीयन",
    "54-चंद्रेश्वर",
    "55-पद्माराजे उद्यान",
    "56-संभाजी नगर बस स्थानक",
    "57-नाथागोळे तालीम",
    "58-संभाजी नगर",
    "62-बुद्ध गार्डन",
    "63-सम्राट नगर",
  ];

  const southVillages = [
    "18-महाडिक वसाहत",
    "20-राजर्षी छत्रपती शाहू मार्केट यार्ड",
    "21-टेंबलाई वाडी",
    "22-विक्रमनगर",
    "24-साईक्स एक्सटेन्शन",
    "40-दौलत नगर",
    "41-प्रतिभानगर",
    "42-पांजरपोळ",
    "51-लक्षतीर्थ वसाहत",
    "52-बलराम कॉलनी",
    "58-संभाजी नगर",
    "59-नेहरू नगर",
    "60-जवाहर नगर",
    "61-सुभाष नगर",
    "62-बुद्ध गार्डन",
    "63-सम्राट नगर",
    "64-शिवाजी विद्यापीठ-कृषी महाविद्यालय",
    "65-राजेंद्र नगर",
    "66-स्वातंत्र्य सैनिक कॉलनी",
    "67-रामानंदनगर - जरगनगर",
    "68-कळंबा फिल्टर हाउस",
    "69-तपोवन",
    "70-राजलक्ष्मी नगर",
    "71-रंकाळा तलाव",
    "72-फुलेवाडी",
    "73-फुलेवाडी रिंग रोड",
    "74-सानेगुरुजी वसाहत",
    "75-आपटेनगर - तुळजाभवानी",
    "76-साळोखे नगर",
    "77-शासकीय मध्यवर्ती कारागृह",
    "78-रायगड कॉलनी - बाबा जरगनगर",
    "79-सुर्वे नगर",
    "80-कणेरकरनगर-कॉ.क्रांतिसिंह नाना पाटील नगर",
    "81-कॉ.क्रांतिसिंह नाना पाटील नगर-जिवबा नाना पार्क",
  ];

  const southRuralVillages = [
    "उंचगांव",
    "वळीवडे",
    "गांधीनगर",
    "चिंचवाड",
    "मुडशिंगी",
    "न्यू वाडदेवसाहत",
    "वसगडे",
    "सांगवडे",
    "सांगवडेवाडी",
    "हलसवडे",
    "नेर्ली",
    "तामगांव",
    "उजळाईवाडी",
    "गोकुळ शिरगांव",
    "सरनोबतवाडी",
    "कणेरीवाडी",
    "कणेरी",
    "कोगील खुर्द",
    "कोगील बुद्रुक",
    "कंदलगांव",
    "मोरेवाडी",
    "पाचगांव",
    "गिरगांव",
    "वड्डवाडी",
    "द-याचे वडगांव",
    "नंदगांव",
    "दिंडनेर्ली",
    "नागांव",
    "इस्पूर्ली",
    "चुये",
    "कावणे",
    "निगवे खालसा",
    "हणबरवाडी",
    "कळंबा",
    "खेबवडे",
    "वडकशिवाले",
  ];

  const handleRegionChange = (e) => {
    setSelectedRegion(e.target.value);
    setSelectedVillage(""); // Reset selected village when region changes
  };

  const handleVillageChange = (e) => {
    setSelectedVillage(e.target.value);
  };

  const [otherRegionText, setOtherRegionText] = useState("");

  const handleOtherRegionTextChange = (e) => {
    setOtherRegionText(e.target.value);
  };

  const handleNavigate = () => {
    window.location.href = "https://kolhapurjobfair.com/";
  };

  const logos = [];
  for (let i = 1; i <= 45; i++) {
    logos.push(
      require(`../Images/logos all/logos all-${i
        .toString()
        .padStart(2, "0")}.jpg`)
    );
  }

  return (
    <div>
      <Header />
      {/* Section 1 */}
      <div className="container-fluid contactus_Bg  d-flex align-items-center">
        <div className="container">
          <div className="row ">
            <div className="col-sm-12">
              <div className=" text-white  text-center">
                <h1 className="heading">Kolhapur Dakshin Job Fair</h1>
              </div>
              <div className=" text-white text-center  ">
                <h2 className="Sub-Font">
                  We Believe In Empowering Individuals at Every Educational
                  <br /> Level to Embark on Their Desired Career Paths.
                </h2>
              </div>
              <br />
              <div className=" text-white text-center">
                <h2 className="Sub-Font">
                  <strong>
                    Venue:
                    <br /> D. Y. PATIL KNOWLEDGE CAMPUS, SALOKHE NAGAR,
                    <br /> KOLHAPUR, MAHARASHTRA 416001
                  </strong>
                </h2>
              </div>
              <br />
              <div className=" text-white text-center">
                <h2 className="Sub-Font">
                  <strong>
                    Dates:
                    <br /> 05-11-2023, 06-11-2023
                  </strong>
                </h2>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6 d-flex candidate-btn">
                  <button
                    type="button"
                    className="btn button-default-class  mt-4"
                    onClick={() => {
                      // Scroll to the form container when the button is clicked
                      const formContainer =
                        document.getElementById("scrollToForm");
                      formContainer.scrollIntoView({ behavior: "smooth" });
                    }}
                  >
                    <strong>Apply for Job</strong>
                  </button>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-6 d-flex employer-btn">
                  <button
                    type="button"
                    onClick={handleNavigate}
                    className="btn button-default-class  mt-4"
                  >
                    <strong>Employer</strong>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section 2 */}
      <div className="container d-flex justify-content-center"></div>
      <div className="container d-flex justify-content-center text-center">
        <div className="row pb-3 Job-mohatsav-detail">
          <div className="col-sm-12 col-md-12 col-lg-6 pt-5">
            <Carousel className="ps-0">
              <Carousel.Item>
                <img
                  className="Politicians"
                  src={SP}
                  alt="Mr. Satej Patil - MLC, Former Minister of State Vice Chairman , D. Y Patil Group "
                ></img>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="Politicians"
                  src={RP}
                  alt="Mr. Ruturaj Patil, MLA South Kolhapur , Trustee DY Patil , group "
                ></img>
              </Carousel.Item>
            </Carousel>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-6 ">
            <div className="">
              <div className="row Welcome-text">
                <div className="col-12 fs-1 sub-heading  d-flex align-items-center ">
                  <h1 className="">
                    <b>Welcome to Job Mohatsav</b>
                  </h1>
                </div>
                <div className="Underline-img-content d-flex">
                  <img
                    className="Heading-underline"
                    src={Underline}
                    alt="underline"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 MLA-carousel ">
              <Carousel>
                <Carousel.Item>
                  <p>
                    आमदार सतेज पाटील यांनी महाराष्ट्र राज्याचे गृह राज्यमंत्री
                    म्हणून दोन टर्म जबाबदारी सांभाळली आहे. ते डी. वाय. पाटील
                    ग्रुपचे उपाध्यक्ष आहेत. कोल्हापूरचे पालकमंत्री कार्यरत
                    असताना अनेक नाविन्यपूर्ण योजना राबविल्या आहेत.
                    <br />
                    <br /> कोल्हापुरातील पायाभूत सुविधांचा विकास करण्यासह आय.टी
                    पार्क होण्यासाठी सातत्याने पाठपुरावा केला आहे. मिशन रोजगार
                    च्या माध्यमातून माझं कोल्हापूर माझा रोजगार हा उपक्रम
                    त्यांच्या पुढाकारातून झाला .
                  </p>
                </Carousel.Item>
                <Carousel.Item>
                  <p>
                    आमदार ऋतुराज संजय पाटील २०१९ पासून कोल्हापूर दक्षिण
                    मतदारसंघाचे लोकप्रतिनिधी आहेत. तसेच डी. वाय. पाटील ग्रुपचे
                    ट्रस्टी आहेत. आमदार ऋतुराज पाटील यांनी उभारलेल्या हॉटेल
                    सयाजी या हॉटेलच्या माध्यमातून पर्यटनाला चालना मिळाली असून
                    अनेक रोजगाराच्या संधी उपलब्ध झाल्या आहेत. मिशन रोजगार या
                    उपक्रमाद्वारे कोल्हापूर दक्षिण मतदारसंघातील युवा पिढीला
                    नोकरीच्या संधी उपलब्ध करून दिल्या आहेत. <br />
                    <br />
                    तसेच कोल्हापूर इन्क्युबेशनच्या माध्यमातून स्टार्ट अप ना
                    प्रोत्साहन दिले आहे. युवा पिढीबरोबर गृहिणींनाही कौशल्य
                    विकासाचे प्रशिक्षण देऊन त्यांना उद्योग व्यवसाय तसेच
                    नोकरीच्या संधी मिळवून देण्यासाठी ते सातत्याने प्रयत्नशील
                    असतात. या प्रशिक्षणामुळे अनेक युवक युवती तसेच गृहिणींनी
                    स्वतःचा व्यवसाय सुरु केला आहे.
                  </p>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 pt-3 text-center  d-flex justify-content-center">
            <div className="d-flex justify-content-center">
              <h3 className="noble">
                <strong>
                  Join us in this noble endeavor. Let's unite for a brighter
                  tomorrow, one job opportunity at a time.Together, we can make
                  a lasting impact!
                </strong>
              </h3>
            </div>
          </div>
        </div>
      </div>

      {/* Section 3  */}
      <div className="container-fluid py-3">
        <div className="container slider">
          <div className="row">
            <div className="col-12">
              <div
                id="carouselExampleControls"
                class="carousel slide"
                data-ride="carousel"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img
                      class="d-block w-100"
                      src={Slide1}
                      alt="Grand Job Fair in South Kolhapur, Maharashtra"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      class="d-block w-100"
                      src={Slide2}
                      alt="Second slide"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      class="d-block w-100"
                      src={Slide3}
                      alt="Grand Job Fair in South Kolhapur, Maharashtra"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      class="d-block w-100"
                      src={Slide4}
                      alt="Grand Job Fair in South Kolhapur, Maharashtra"
                    />
                  </div>
                </div>
                <a
                  class="carousel-control-prev"
                  href="#carouselExampleControls"
                  role="button"
                  data-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only"></span>
                </a>
                <a
                  class="carousel-control-next"
                  href="#carouselExampleControls"
                  role="button"
                  data-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section 4 */}
      <div id="scrollToForm" className="container-fluid form-bg">
        <div className="container registration-form">
          <div className="">
            <div className="row">
              <div className="col-12 text-white text-center  pb-4">
                <h3 className="heading-1">
                  Register Now And Get Your Desired Job
                </h3>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit} ref={formRef}>
            {/* Name */}
            <div class="row g-4">
              <div class="col-sm-12 col-md-12 col-lg-6 py-2 input-col">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Your Full Name*"
                  aria-label="First name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                />
              </div>

              {/* Address */}
              <div className="col-sm-12 col-md-12 col-lg-6 py-2 input-col">
                <input
                  type="text"
                  placeholder="Enter Your Address*"
                  class="form-control"
                  value={Address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </div>
            <div class="row g-4">
              <div class="col-sm-12 col-md-12 col-lg-6 py-2 ">
                {/* Mobile Number */}
                <input
                  type="tel"
                  class="form-control"
                  placeholder="Enter Your Mobile Number*"
                  aria-label="Enter Your Mobile Number"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  minLength={10}
                  maxLength={10}
                  required
                />
              </div>

              {/* Qualification */}
              <div class="col-sm-12 col-md-12 col-lg-3 py-2 input-col">
                <select
                  className="form-select"
                  id="autoSizingSelect"
                  value={selectedQualification}
                  onChange={(e) => setSelectedQualification(e.target.value)}
                  required
                >
                  <option value="">Qualification*</option>
                  <option value="10th">10th</option>
                  <option value="12th">12th</option>
                  <option value="Graduate">Graduate</option>
                  <option value="Post Graduate">Post Graduate</option>
                  <option value="ITI">ITI</option>
                </select>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-3 py-2 input-col mob-view">
                <div>
                  <input
                    type="text"
                    placeholder="Specialization*"
                    className="form-control"
                    id="otherQualification"
                    value={specialization}
                    onChange={(e) => setSpecialization(e.target.value)}
                    required={selectedQualification !== ""} 
                    disabled={selectedQualification === ""}
                  />
                </div>
              </div>
            </div>

            {/* Email */}
            <div class="row g-4">
              <div class="col-sm-12 col-md-12 col-lg-6 py-2 input-col">
                <input
                  type="email"
                  class="form-control"
                  placeholder="Enter Your Email Address"
                  aria-label="Your Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              {/* Region */}
              <div className="col-sm-12 col-md-12 col-lg-3 py-2 input-col">
                <select
                  class="form-control"
                  id="regionSelect"
                  value={selectedRegion}
                  onChange={handleRegionChange}
                  required
                >
                  <option value="">Select Region*</option>
                  {regions.map((region) => (
                    <option key={region} value={region}>
                      {region === "North"
                        ? "North Kolhapur"
                        : region === "South"
                        ? "South Kolhapur(City)"
                        : region === "SouthRural" 
                        ? "South Kolhapur(Rural)"
                        : region}
                    </option>
                  ))}
                  <option value="Other">Other</option>
                  {/* Add the "Other" option */}
                </select>
              </div>
              {selectedRegion !== "Other" && !otherRegionText && (
                <div className="col-sm-12 col-md-12 col-lg-3 py-2 input-col">
                  <select
                    class="form-control"
                    id="villageSelect"
                    value={selectedVillage}
                    onChange={handleVillageChange}
                    required
                  >
                    <option value="">Select Area ({selectedRegion})</option>
                    {selectedRegion === "North"
                      ? northVillages.map((village) => (
                          <option key={village} value={village}>
                            {village}
                          </option>
                        ))
                      : selectedRegion === "South"
                      ? southVillages.map((village) => (
                          <option key={village} value={village}>
                            {village}
                          </option>
                        ))
                      : selectedRegion === "SouthRural" 
                      ? southRuralVillages.map((village) => (
                          <option key={village} value={village}>
                            {village}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              )}
              {(selectedRegion === "Other" || otherRegionText) && (
                <div className="col-sm-12 col-md-12 col-lg-3 py-2 input-col">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Your Village Name"
                    id="otherRegionInput"
                    value={selectedVillage}
                    onChange={(e) => {
                      setSelectedVillage(e.target.value);
                    }}
                  />
                </div>
              )}
            </div>

            <div class="row g-4">
              {/* Adhar */}
              <div class="col-sm-12 col-md-12 col-lg-6 py-2 ">
                <input
                  type="tel"
                  class="form-control"
                  placeholder="Enter Your Adhar Number"
                  aria-label="Enter Your Adhar Number"
                  minLength={12}
                  maxLength={12}
                  value={AdharNumber}
                  onChange={(e) => setAdharNumber(e.target.value)}
                />
              </div>

              {/* Resume */}
              <div class="col-sm-12 col-md-12 col-lg-6 py-2 input-col">
                {/* <input
                  class="form-control"
                  placeholder="Upload"
                  aria-label="Upload"
                  type="file"
                  ref={resumeInputRef}
                  name="resume"
                  accept=".pdf,.doc,.docx"
                  onChange={handleFileUpload}
                /> */}
                <div class="input-group custom-file-button">
                  <label class="input-group-text" for="inputGroupFile">
                    Attach Resume
                  </label>
                  <input
                    type="file"
                    class="form-control"
                    id="inputGroupFile"
                    ref={resumeInputRef}
                    onChange={handleFileUpload}
                    accept=".pdf, .doc, .docx"
                  />
                </div>
              </div>
            </div>

            <div className="row pt-3 pb-2 ">
              <div className="col-12 d-flex justify-content-center align-items-center">
                <button className="Submit-btn" type="submit">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Section 5 */}
      <div className="container py-5">
        <div className="row d-flex">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className=" ">
              <div className="mission-heading">
                <h2 className="mission">
                  ABOUT KOLHAPUR
                  <br />
                  DAKSHIN JOB
                  <br />
                  FAIR 2023
                </h2>
              </div>
            </div>
            <div className="second-underline">
              <img className="underline" src={Underline} alt="underline" />
            </div>
            <div className="row pt-1">
              <div className="col-12 carousel">
                <Carousel className="ps-0">
                  <Carousel.Item>
                    <h3 className="pt-5">
                      <strong>Our Mission</strong>
                    </h3>
                    <p>
                      The mission of the Kolhapur Job Fair is to connect local
                      employers with talented job seekers in the region,
                      fostering economic growth and development.
                    </p>
                  </Carousel.Item>
                  <Carousel.Item>
                    <h3 className="pt-5">
                      <strong>Our Vision</strong>
                    </h3>
                    <p>
                      Our vision is to create a platform that facilitates
                      meaningful employment opportunities, promotes skill
                      development, and contributes to the overall progress of
                      Kolhapur.
                    </p>
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>

            <div className="row pt-1 pb-3">
              <div className="col-12 py-3 Register-btn">
                <button
                  type="button"
                  className="btn Register mt-4"
                  onClick={() => {
                    // Scroll to the form container when the button is clicked
                    const formContainer =
                      document.getElementById("scrollToForm");
                    formContainer.scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  Register Now
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 Register-img d-flex justify-content-center">
            <img className="Register-img" src={img1} alt="" />
          </div>
        </div>
      </div>

      {/* Logo Slider */}
      <div className="container py-3">
        <div className="row d-flex">
          <div className="col-12 pb-3 pt-2">
            <Marquee velocity={100}>
              {logos.map((logo, index) => (
                
                <img
                key={index}
                src={logo}
                alt={`Logo ${index + 1}`}
                style={{ width: "13rem" }} 
              />
              ))}
            </Marquee>
          </div>
        </div>
      </div>

      {/* Collab with */}
      <div className="container pt-4 pb-3">
        <div className="row text-center">
          <strong>Collabaration with</strong>
        </div>
        <div className="row d-flex align-items-center justify-content-center pb-4 pt-1">
          <div className="col-lg-6 col-md-12 col-sm-12 collab-1 ">
            <img className="collab-1" src={Nasscom} style={{width:"25%"}} alt=""/>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 collab-2">
            <img src={Future} style={{width:"25%"}} alt=""/>
          </div>
        </div>
      </div>

      {/* Google Map */}
      <div className="container-fluid map pt-3">
        <div className="container pt-3 pb-5">
          <div className="row">
            <div className="col-lg-6 col-sm-12 col-md-12 ">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d882.4408513350132!2d74.20540895525868!3d16.667727547480542!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc05552f4000001%3A0x81166eb04377a838!2sD.%20Y.%20Patil%20College%20Of%20Engineering!5e0!3m2!1sen!2sin!4v1696520813822!5m2!1sen!2sin"
                width="100%"
                height="100%"
                style={{ border: "none" }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Maps"
              ></iframe>
            </div>
            <div className="col-lg-6 col-sm-12 col-md-12 pt-3">
              <div className="Sub-Font text-white map-text">
                <strong>
                  Venue:
                  <br /> D. Y. PATIL KNOWLEDGE CAMPUS, <br /> SALOKHE NAGAR,
                  KOLHAPUR, <br />
                  MAHARASHTRA 416001
                </strong>
              </div>
              <br />
              <div className="Sub-Font text-white map-text">
                <strong>
                  Dates:
                  <br /> 05-11-2023, 06-11-2023
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
