import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DataTable from "react-data-table-component";
import Header from "../Component/Header";
import { TbLogout } from "react-icons/tb";
// import Select from "react-select";

export default function CompanyAttendance() {
  const navigate = useNavigate();
  const [result, setResult] = useState([]);
  const [totalCandidate, setTotalCandidate] = useState(0);
  const refreshInterval = 7000;
  // const [isAddingApplicant, setIsAddingApplicant] = useState(false);
  // // const [applicantname, setApplicantname] = useState("");
  // const [options, setOptions] = useState([{label:"Select",value:""}]);


  // const handleAddApplicantClick(){
  //   setIsAddingApplicant(true);

    // try {
      
    // } catch (error) {
    //   console.error("Error fetching data:", error);
    // }
  // };

  useEffect(() => {
    if (localStorage.getItem("isloggedin") !== "yes") {
      navigate("/floor-login");
    }
  }, [navigate]);

  const fetchData = async () => {
    try {
      const data = {
        candidateonfloor: true,
        flooraa: localStorage.getItem("floor"),
      };

      const response = await axios.post(
        "https://kolhapurdakshin.com/Jobmohatsav/flooradmin/floordashboard.php",
        data
      );

      const jsonData = response.data;
      const resultArray = jsonData.findallfloorapplicant;
      const totalCandidateCount = jsonData.findallfloorapplicant.length;
      setResult(resultArray);
      console.log(resultArray);
      setTotalCandidate(totalCandidateCount);


      const data1 = { floorapplistin: true };

      const response1 = await axios.post("https://kolhapurdakshin.com/Jobmohatsav/flooradmin/floordashboard.php",data1);

      const jsonData1 = response1.data;
      console.log(jsonData1)
      // var apparr = [];


    } catch (error) {
      console.error("Error fetching data:", error);
    }

    // const data = { floorapplistin: true };

    // const response = await axios.post("https://kolhapurdakshin.com/Jobmohatsav/flooradmin/floordashboard.php",data);

    // const jsonData = response.data;
    // console.log(jsonData)
    // var apparr = [];
    // for(var i=0;i<=response.data.findapplicantlist.length;i++){
      // apparr.push({ value : response.data.findapplicantlist[i]['career_id'] , label : response.data.findapplicantlist[i]['fullName'] })
    // }
    // const resultArray = jsonData.findallfloorapplicant;
    // const totalCandidateCount = jsonData.findallfloorapplicant.length;
    // setResult(resultArray);
    // console.log(resultArray);
    // setTotalCandidate(totalCandidateCount);

  };

  const handleOut = async (applicantId) => {
    try {
      const data = {
        floorappout: true,
        applicant_id: applicantId,
        floor: localStorage.getItem("floor"),
      };

      await axios.post(
        "https://kolhapurdakshin.com/Jobmohatsav/flooradmin/floordashboard.php",
        data
      );
      fetchData();
    } catch (error) {
      console.error("Error in API request:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, refreshInterval);
    console.log("Datafetch after 7 sec");

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "darkblue",
        color: "white",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.applicant_id,
      sortable: true,
      width: "70px",
    },
    {
      name: "Name",
      selector: (row) => row.fullName,
      sortable: true,
    },
    {
      name: "Qualification",
      selector: (row) => row.Qualification,
      sortable: true,
    },

    {
      name: "Mobile Number",
      selector: (row) => row.mobileNumber,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <button
          className="btn btn-danger"
          onClick={() => handleOut(row.applicant_id)}
        >
          Out
        </button>
      ),
    },
  ];

  const handleLogout = () => {
    localStorage.removeItem("isloggedin");

    navigate("/floor-login");
  };

  // const handleAddcandidate = async () => {
   
  //   // setApplicantname("");
  //   setIsAddingApplicant(false);
  // };

  return (
    <>
      <Header />
      <div className="container pb-4">


      <div className="row">
          {/* <div className="col-6 d-flex justify-content-start pb-3">
            <button
              type="button"
              className="btn btn-outline-success d-flex align-items-center"
              onClick={handleAddApplicantClick}
            >
              Add Applicant
            </button>
           
          </div> */}
          
          <div className="col-12 d-flex justify-content-end pb-3">
            <button
              type="button"
              onClick={handleLogout}
              className="btn btn-outline-danger d-flex align-items-center"
            >
              <TbLogout />
              <strong>Logout</strong>
            </button>
          </div>

          {/* {isAddingApplicant && (
              <div className="col-6">
                
                <Select
                  options={options}
                  // onChange={handleSelectChange}
                  placeholder="Select an option"
                />

                <button type="button" className="btn btn-success mt-3" onClick={handleAddcandidate}>
                  Add
                </button>
              </div>
            )} */}
        </div>


        <div className="row">
          <div className="col-12 d-flex justify-content-center text-center fs-4 py-3">
            <strong>
              Candidates On Floor
              <br />
              <span
                style={{
                  backgroundColor: "yellow",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  borderRadius: "6px",
                  fontSize: "4rem",
                }}
              >
                {totalCandidate}
              </span>
            </strong>
          </div>
        </div>

        <DataTable
          data={result}
          columns={columns}
          customStyles={customStyles}
          pagination
        />

      </div>
    </>
  );
}
