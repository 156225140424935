import React from "react";
import Logo from "../Images/Logo.png";
import Dyansha from "../Images/Dyansha.png"

const Header = () => {
  return (
    <nav className="navbar bg-body-tertiary">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img
            src={Logo}
            alt="Logo"
            width="130"
            height="55"
            class="d-inline-block align-text-top ps-4"
          />
        </a>
        <a className="navbar-brand " href="https://dnyanasha.org/">
          <img
            src={Dyansha}
            alt="Logo"
            width="130"
            height="40"
            class="d-inline-block align-text-top ps-4"
          />
        </a>
        
      </div>
    </nav>
  );
};

export default Header;
