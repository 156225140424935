import React, { useState } from "react";
import "../Styles/Login.css";
import Header from "../Component/Header";
import Logo from "../Images/Logo.png";
import tdtl_black from "../Images/tdtl_black.png";
import { useNavigate } from "react-router-dom";

import axios from "axios";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginSuccess, setLoginSuccess] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    const data = new URLSearchParams();
    data.append("username", username);
    data.append("password", password);

    if (username.trim() === "" || password.trim() === "") {
      return;
    }

    try {
      const response = await axios.post(
        "https://kolhapurdakshin.com/Jobmohatsav/jobfairdashboard.php",
        data
      );
      if (response.status === 200) {
        const jsonResponse = response.data;

        if (jsonResponse.admin) {
          console.log(jsonResponse.message); 
          setLoginSuccess(true);
          localStorage.setItem("isloggedin", "yes");
          navigate("/report-admin");
        } 
        else if(jsonResponse.user){
          console.log(jsonResponse.message); 
          setLoginSuccess(true);
          localStorage.setItem("isloggedin", "yes");
          navigate("/report-user");

        }
        else if(jsonResponse.ViewData){
          console.log(jsonResponse.message); 
          setLoginSuccess(true);
          localStorage.setItem("isloggedin", "yes");
          navigate("/view-data");

        }
        else {
          setLoginSuccess(false);

          console.error("Login failed");
          // alert("invalid password or username");
        }
      } else {
        // Handle HTTP error responses
        console.error("HTTP error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <Header />
      <div className="text-center pt-3">
        {/* <h2 className="text-center py-3">
          <strong
            style={{ color: "orange", fontWeight: 700, fontSize: "3rem" }}
          >
            MISSION ROJGAR <br />
          </strong>
        </h2> */}
        <img src={Logo} alt="" />
      </div>
      <div className="container login">
        <div className="row">
          <div className="col-sm-12 col-lg-12 col-md-12 d-flex justify-content-center align-item-center">
            <div className="right-column">
  
              <h3  style={{ color: "#FAA33D", fontWeight: 600, fontSize: "1.5rem", fontFamily: "Montserrat" }}
>
                <strong>Admin Login</strong>
              </h3>
              <form onSubmit={handleLogin}>
                <div className="input-container">
                  <input
                    type="text"
                    placeholder="Enter Username"
                    name="username"
                    required
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className="input-container">
                  <input
                    type="password"
                    placeholder="Enter Password"
                    name="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                {loginSuccess === false ? (
                  <p style={{ color: "red" }}>Invalid username or password</p>
                ) : null}
                <button type="submit" className="login-button">
                  LOGIN
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row pt-2">
          <div className="col-lg-12 col-md-12 col-sm-12 ps-2 pe-2 tdtl-logo d-flex justify-content-center">
            {/* Logo */}
            <a href="https://tdtl.world">
              <img src={tdtl_black} alt="Logo" />
            </a>
          </div>
          <div className="col-12">
            <div className="text-center pb-2">
              © 2024 MISSIONROJGAR. All rights reserved | Designed, Developed &
              Maintained by{" "}
              <a href="https://tdtl.world" style={{ textDecoration: "none" }}>
                The DataTech Labs Inc
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
