import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import "../Styles/viewdata.css";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import Header from "../Component/Header";

export default function ViewData() {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("isloggedin") !== "yes") {
      navigate("/admin-login");
    }
  });
  const [result, setResult] = useState([]);
  const [totalregistration, setTotalregistration] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [qualificationFilter, setQualificationFilter] = useState("");

  const exportToExcel = () => {
    // Extract only the columns you want to export
    const exportData = result.map((row) => ({
      "Sr. No.": row.career_id,
      Name: row.fullName,
      Email: row.email,
      "Mobile Number": row.mobileNumber,
      Qualification: row.Qualification,
      Specialization: row.specialization,
      Status: row.applicant_status,
    }));

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "attendancesheet");
    XLSX.writeFile(wb, "attendancesheet.xlsx");
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = {
        fetchdata: true,
      };
      const response = await axios.post(
        "https://kolhapurdakshin.com/Jobmohatsav/viewdata.php",
        data
      );
      const jsonData = response.data;
      const registration = jsonData.TotalRegistration;
      const resultArray = jsonData.finddata;
      setResult(resultArray);
      setTotalregistration(registration);
    } catch (error) {
      // Handle any error if needed
    } finally {
      setLoading(false);
    }
  };

  const handleStatusToggle = async (career_id) => {
    console.time("StatusToggleTime");

    try {
      const response = await axios.post(
        "https://kolhapurdakshin.com/Jobmohatsav/status.php",
        { manulalpresent: "yes", career_id: career_id }
      );

      console.log(response);
      fetchData();
    } catch (error) {
      console.error("Error updating attendance:", error);
    }

    console.timeEnd("StatusToggleTime");

    setTimeout(() => {
      setButtonLoading(false);
    }, 1000);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filteredData = result.filter((row) => {
    const lowerSearchText = searchText.toLowerCase();
    return (
      (row.fullName.toLowerCase().includes(lowerSearchText) ||
        row.career_id.toString().includes(searchText) ||
        row.email.toLowerCase().includes(lowerSearchText)) &&
      (!qualificationFilter || row.Qualification === qualificationFilter)
    );
  });

  const uniqueQualifications = Array.from(
    new Set(result.map((row) => row.Qualification))
  );

  const columns = [
    {
      name: "Sr. No.",
      selector: (row) => row.career_id,
      sortable: true,
      width: "100px",
    },
    {
      name: "Name",
      selector: (row) => row.fullName,
      sortable: true,
      width: "200px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "200px",
    },
    {
      name: "Mobile Number",
      selector: (row) => row.mobileNumber,
      sortable: true,
      width: "200px",
    },
    {
      name: "Qualification",
      selector: (row) => row.Qualification,
      sortable: true,
      width: "200px",
    },
    {
      name: "Specialization",
      selector: (row) => row.specialization,
      sortable: true,
      width: "200px",
      fontSize: "5px",
    },
    {
      name: "Status",
      selector: (row) => row.applicant_status,
      sortable: true,
      width: "200px",
      cell: (row) => (
        <strong
          style={{
            color: "white",
            backgroundColor:
              row.applicant_status === "Present" ? "#28a745" : "#dc3545",
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingBottom: "2px",
            borderRadius: "5px",
          }}
        >
          {row.applicant_status}
        </strong>
      ),
    },
    {
      name: "Mark Attendance",
      cell: (row, rowIndex) => (
        <button
          onClick={() => handleStatusToggle(row.career_id)}
          className="btn btn-primary"
          disabled={buttonLoading}
        >
          {row.applicant_status === "Present" ? "Mark Absent" : "Mark Present"}
        </button>
      ),
    },
  ];

  const tableCustomStyles = {
    headRow: {
      style: {
        color: "white",
        backgroundColor: "darkblue",
        fontSize: "18px",
        fontWeight: "400",
        borderBottom: "1px solid #fff",
        textAlign: "center",
      },
    },
    rows: {
      style: {
        color: "black",
        // backgroundColor: "#2B2D3F",
        fontSize: "14px",
        fontWeight: "normal",
        textAlign: "center",
        borderBottom: "3px solid #fff",
      },
    },
    headCells: {
      style: {
        paddingLeft: "5px",
        paddingRight: "5px",
      },
    },
    cells: {
      style: {
        paddingLeft: "5px",
        paddingRight: "5px",
      },
    },
  };

  return (
    <>
    <Header/>
      <div className="container-fluid">
        <div className="container fs-3 d-flex justify-content-center py-3">
          <strong>Total Registered Candidate: {totalregistration}</strong>
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-start pb-1 ps-4 mx-1">
            <button
              type="btn"
              onClick={() => exportToExcel(result)}
              className="btn btn-dark" style={{fontSize:"14px"}}
            >
              <strong>Export Excel</strong>
            </button>
          </div>
        </div>

        <div className="row p-3">
          <div className="col-4">
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search Name, Job ID, or Email"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="mb-3">
              <select
                className="form-control"
                value={qualificationFilter}
                onChange={(e) => setQualificationFilter(e.target.value)}
              >
                <option value="">All Qualifications</option>
                {uniqueQualifications.map((qualification, index) => (
                  <option key={index} value={qualification}>
                    {qualification}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-4"></div>
        </div>

        <div className="container-fluid w-100">
          {loading ? (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <DataTable
              data={filteredData}
              columns={columns}
              style={{ textAlign: "center" }}
              customStyles={tableCustomStyles}
              pagination
            />
          )}
        </div>
      </div>
    </>
  );
}
