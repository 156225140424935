import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";
import Header from "../Component/Header";
import { TbLogout } from "react-icons/tb";
import { AiFillEye } from "react-icons/ai";

export default function CompanyAttendance() {
  const navigate = useNavigate();
  const [result, setResult] = useState([]);
  const [totalCandidate, setTotalCandidate] = useState(0);
  const [searchText, setSearchText] = useState("");

  const refreshInterval = 10000;

  const applySearchFilter = (data, searchText) => {
    const lowerSearchText = searchText.toLowerCase();
    return data.filter((row) => {
      return (
        row.fullName.toLowerCase().includes(lowerSearchText) ||
        row.career_id.toString().includes(searchText)
      );
    });
  };
  const filteredData = applySearchFilter(result, searchText);


  const exportToExcel = (resultarr) => {
    var filterresarr = resultarr.filter((res) => res.notyfied === "Yes");

    const exportData = filterresarr.map((row) => ({
      applicant_id: row.applicant_id,
      fullName: row.fullName,
      email: row.email,
      mobileNumber: row.mobileNumber,
      Qualification: row.Qualification,
      specialization: row.specialization,
      notified: row.notyfied,
      status: row.candidatestatus,
      result: row.result,
    }));

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Candidates");
    XLSX.writeFile(wb, "notified_candidates.xlsx");

    fetchData();
  };

  useEffect(() => {
    if (localStorage.getItem("isloggedin") !== "yes") {
      navigate("/company-login");
    }
  }, [navigate]);

  const fetchData = async () => {
    try {
      const data = {
        companyattemplist: true,
        companyid: localStorage.getItem("company_id"),
      };

      const response = await axios.post(
        "https://kolhapurdakshin.com/Jobmohatsav/companyemplist.php",
        data
      );

      const jsonData = response.data;
      const resultArray = jsonData.findcompanyemplist;
      const totalCandidateCount = jsonData.findcompanyemplist.length;
      setResult(resultArray);
      console.log(resultArray);
      setTotalCandidate(totalCandidateCount);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleNotify = async (row) => {
    try {
      const data = {
        companyid: localStorage.getItem("company_id"),
        applicantid: row.applicant_id,
        notify: "Yes",
        applied_id: row.applied_id,
        callapplicant: true,
      };

      const response = await axios.post(
        "https://kolhapurdakshin.com/Jobmohatsav/companyemplist.php",
        data
      );

      if (response.data.updatenofied) {
        fetchData();
      }
    } catch (error) {
      console.error("Error updating Notify:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, refreshInterval);
    console.log("Datafetch after 10 sec");

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const customStyles = {
    headRow: {
      style: {
        width:"100%",
        backgroundColor: "darkblue",
        color: "white",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.applicant_id,
      sortable: true,
      width: "70px",
    },
    {
      name: "Name",
      selector: (row) => row.fullName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "200px",
    },
    {
      name: "Qualification",
      selector: (row) => row.Qualification,
      sortable: true,
    },
    {
      name: "Specialization",
      selector: (row) => row.specialization,
      sortable: true,
    },
    {
      name: "Mobile Number",
      selector: (row) => row.mobileNumber,
      sortable: true,
    },
    {
      name: "View Resume",
      cell: (row) =>
        row.resume ? (
          <button type="button" className="btn btn-primary">
            <a
              href={row.resume}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none", color: "white" }}
            >
              <span style={{ display: "flex", alignItems: "center" }}>
                <AiFillEye style={{ fontSize: "15px" }} /> Resume
              </span>
            </a>
          </button>
        ) : (
          "Not Uploaded"
        ),
    },
    {
      name: "Notify",
      cell: (row) => (
        <button
          type="button"
          className={`btn ${
            row.notyfied === "Yes"
              ? "btn-success"
              // : row.notyfied === "Engage"
              // ? "btn-danger"
              : "btn-warning"
          } text-white`}
          onClick={() => handleNotify(row)}
        >
          <strong>
            {row.notyfied === "Yes"
              ? "Notified"
              // : row.notyfied === "Engage"
              // ? "Engage"
              : "Notify"}
          </strong>
        </button>
      ),
    },

    {
      name: "Status",
      cell: (row) => {
        const handleChangeStatus = (e) => {
          const newStatus = e.target.value;
          const postData = {
            applicantid: row.applicant_id,
            status: newStatus,
            companyid: row.company_id,
            applied_id: row.applied_id,
            changestatus: true,
          };

          row.candidatestatus = newStatus;

          fetch("https://kolhapurdakshin.com/Jobmohatsav/companyemplist.php", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
          })
            .then((response) => response.json())
            .then((data) => {
              fetchData();
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        };

        return (
          <select
            className="form-select"
            value={row.candidatestatus || "Not Visited"}
            onChange={handleChangeStatus}
            aria-label="Default select example"
          >
            {/* <option>{row.candidatestatus}</option> */}
            <option value="Pending">Pending</option>
            <option value="Interview Done">Interview Done</option>
            <option value="Interview Not Done">Interview Not Done</option>
            <option value="Resume Collected">Resume Collected</option>
          </select>
        );
      },
    },

   
    {
      name: "Result",
      cell: (row) => {
        const handleResultChange = (e) => {
          const newResult = e.target.value;
          const postData = {
            applicantid: row.applicant_id,
            result: newResult,
            companyid: row.company_id,
            changerResult: true,
          };

          row.result = newResult;

          fetch("https://kolhapurdakshin.com/Jobmohatsav/companyemplist.php", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
          })
            .then((response) => response.json())
            .then((data) => {
              fetchData();
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        };

        return (
          <select
            className="form-select"
            value={row.result || "Not Selected"}
            onChange={handleResultChange}
            aria-label="Result select"
          >
            {/* <option>{row.result}</option> */}
            <option value="pending">Pending</option>
            <option value="selected">Selected</option>
            <option value="not_selected">Not Selected</option>
            <option value="shortlisted">Shortlisted</option>
            <option value="not_eligible">Not Eligible</option>
          </select>
        );
      },
    },
  ];

  const handleLogout = () => {
    localStorage.removeItem("isloggedin");

    navigate("/company-login");
  };

  return (
    <>
      <Header />
      <div className="container ">
        <div
          className="text-center"
          style={{ fontSize: "2.5rem", fontWeight: "bolder", color:"darkblue" }}
        >
          {localStorage.getItem("company_name")} Dashboard
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-center fs-2 pb-3">
            <strong
              style={{
                backgroundColor: "yellow",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingBottom: "3px",
                borderRadius: "5px",
              }}
            >
              Total Applications: {totalCandidate}
            </strong>
          </div>
        </div>

        <div className="row">
          <div className="col-4 d-flex justify-content-start pb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search Name or Job ID"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          
          <div className="col-8 d-flex justify-content-end pb-3">
            <button
              type="btn"
              onClick={() => exportToExcel(result)}
              className="btn btn-dark"
            >
              <strong>Export Excel</strong>
            </button>
          </div>
        </div>

        <DataTable
          data={filteredData}
          columns={columns}
          customStyles={customStyles}
          pagination
        />
        <div className="row">
          <div className="col-12 d-flex justify-content-end pb-3">
            <button
              type="button"
              onClick={handleLogout}
              className="btn btn-outline-danger d-flex align-items-center"
            >
              <TbLogout />
              <strong>Logout</strong>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}