import React, { useState } from "react";
import axios from "axios";
import "../Styles/JobMohatsav.css";
import Header from "../Component/Header";

function FeedbackForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    overallExperience: "",
    communication: "",
    interviewProcess: "",
    companyCulture: "",
    feedback: "",
  });

  const [submitted, setSubmitted] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    try {
      const response = await axios.post(
        "https://kolhapurdakshin.com/Jobmohatsav/feedbackapi.php",
        formData
      );
      if (
        response.status === 200 &&
        response.data === "Feedback saved successfully"
      ) {
        const jsonResponse = response.data;
      } else {
        // Handle HTTP error responses
        console.error("HTTP error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
    <Header/>
      <div className="container feedback-form">
        <div className="row">
          <h2
            className="pt-0 text-center"
            style={{ color: "orange", fontWeight: "bold" }}
          >
            Job Fair Feedback Form
          </h2>
        </div>

        {submitted ? (
          <div className="mt-3 text-center">
            <h2>
              <strong>Thank You!!!</strong>
            </h2>
            <p>Your feedback has been submitted.</p>
          </div>
        ) : (
          <div className="row">
            <form className="mt-4" onSubmit={handleSubmit}>
              <div className="col-12">
                <div className="form-group py-2">
                  <label>Candidate Name:</label>
                  <input
                    className="form-control "
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="col-12">
                <div className="form-group py-2">
                  <label>Email:</label>
                  <input
                    className="form-control "
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group py-2">
                  <label>Mobile No:</label>
                  <input
                    className="form-control "
                    type="tel"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group py-2">
                  <label>How was your overall Experience?</label>
                  <div>
                    <label className="radio-inline pe-2">
                      <input
                        type="radio"
                        name="overallExperience"
                        value="Excellent"
                        checked={formData.overallExperience === "Excellent"}
                        onChange={handleInputChange}
                      />
                      Excellent
                    </label>
                    <label className="radio-inline pe-2">
                      <input
                        type="radio"
                        name="overallExperience"
                        value="Good"
                        checked={formData.overallExperience === "Good"}
                        onChange={handleInputChange}
                      />
                      Good
                    </label>
                    <label className="radio-inline pe-2">
                      <input
                        type="radio"
                        name="overallExperience"
                        value="Fair"
                        checked={formData.overallExperience === "Fair"}
                        onChange={handleInputChange}
                      />
                      Fair
                    </label>
                    <label className="radio-inline pe-2">
                      <input
                        type="radio"
                        name="overallExperience"
                        value="Poor"
                        checked={formData.overallExperience === "Poor"}
                        onChange={handleInputChange}
                      />
                      Poor
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="form-group py-2">
                  <label>
                    Did you get the effective support from Our team?
                  </label>
                  <div>
                    <label className="radio-inline pe-2">
                      <input
                        type="radio"
                        name="communication"
                        value="Excellent"
                        checked={formData.communication === "Excellent"}
                        onChange={handleInputChange}
                      />
                      Excellent
                    </label>
                    <label className="radio-inline pe-2">
                      <input
                        type="radio"
                        name="communication"
                        value="Good"
                        checked={formData.communication === "Good"}
                        onChange={handleInputChange}
                      />
                      Good
                    </label>
                    <label className="radio-inline pe-2">
                      <input
                        type="radio"
                        name="communication"
                        value="Fair"
                        checked={formData.communication === "Fair"}
                        onChange={handleInputChange}
                      />
                      Fair
                    </label>
                    <label className="radio-inline pe-2">
                      <input
                        type="radio"
                        name="communication"
                        value="Poor"
                        checked={formData.communication === "Poor"}
                        onChange={handleInputChange}
                      />
                      Poor
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="form-group py-2">
                  <label>
                    Were there enough participating companies and organizations
                    in your field of interest?
                  </label>
                  <div>
                    <label className="radio-inline pe-2">
                      <input
                        type="radio"
                        name="interviewProcess"
                        value="Excellent"
                        checked={formData.interviewProcess === "Excellent"}
                        onChange={handleInputChange}
                      />
                      Excellent
                    </label>
                    <label className="radio-inline pe-2">
                      <input
                        type="radio"
                        name="interviewProcess"
                        value="Good"
                        checked={formData.interviewProcess === "Good"}
                        onChange={handleInputChange}
                      />
                      Good
                    </label>
                    <label className="radio-inline pe-2">
                      <input
                        type="radio"
                        name="interviewProcess"
                        value="Fair"
                        checked={formData.interviewProcess === "Fair"}
                        onChange={handleInputChange}
                      />
                      Fair
                    </label>
                    <label className="radio-inline pe-2">
                      <input
                        type="radio"
                        name="interviewProcess"
                        value="Poor"
                        checked={formData.interviewProcess === "Poor"}
                        onChange={handleInputChange}
                      />
                      Poor
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="form-group py-2">
                  <label>
                    Did you get the opportunity to network with potential
                    employers and other students?
                  </label>
                  <div>
                    <label className="radio-inline pe-2">
                      <input
                        type="radio"
                        name="companyCulture"
                        value="Excellent"
                        checked={formData.companyCulture === "Excellent"}
                        onChange={handleInputChange}
                      />
                      Excellent
                    </label>
                    <label className="radio-inline pe-2">
                      <input
                        type="radio"
                        name="companyCulture"
                        value="Good"
                        checked={formData.companyCulture === "Good"}
                        onChange={handleInputChange}
                      />
                      Good
                    </label>
                    <label className="radio-inline pe-2">
                      <input
                        type="radio"
                        name="companyCulture"
                        value="Fair"
                        checked={formData.companyCulture === "Fair"}
                        onChange={handleInputChange}
                      />
                      Fair
                    </label>
                    <label className="radio-inline pe-2">
                      <input
                        type="radio"
                        name="companyCulture"
                        value="Poor"
                        checked={formData.companyCulture === "Poor"}
                        onChange={handleInputChange}
                      />
                      Poor
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group py-2">
                  <label>
                    Your suggestions for improving our future job fair events
                  </label>
                  <textarea
                    className="form-control"
                    name="feedback"
                    value={formData.feedback}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="pb-3">
                <button type="submit" className="btn btn-success">
                  Submit
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
}

export default FeedbackForm;
