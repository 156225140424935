import React, { useEffect, useState } from "react";
import "../Styles/Login.css";
import Header from "../Component/Header";
import Logo from "../Images/Logo.png";
import tdtl_black from "../Images/tdtl_black.png";
import { useNavigate } from "react-router-dom";

import axios from "axios";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginSuccess, setLoginSuccess] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("isloggedin") === "yes") {
      navigate("/floor-login");
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    

    const data = new URLSearchParams();
    data.append("floorlogin", "yes");
    data.append("username", username);
    data.append("password", password);

    data.append("floorlogin", "true");
    

    if (username.trim() === "" || password.trim() === "") {
      return;
    }

    try {
      const response = await axios.post(
        "https://kolhapurdakshin.com/Jobmohatsav/flooradmin/floorlogin.php",
        data
      );
      if (response.data.login === true) {
          setLoginSuccess(true);
          localStorage.setItem("isloggedin", "yes");
          if(username === "sfwa"){

            localStorage.setItem("floor", "SF");
          }
          else if(username === 'ffwa')
          {
            localStorage.setItem("floor", "FF");
          }
          else if(username === 'tfwa')
          {
            localStorage.setItem('floor', "TF")
          }
          else if(username === 'gfwa')
          {
            localStorage.setItem('floor', "GF")
          }
          else if(username === 'winga')
          {
            localStorage.setItem('floor', "A")
          }
          else if(username === 'mh')
          {
            localStorage.setItem('floor', "MH")
          }
          else if(username === 'wingb')
          {
            localStorage.setItem('floor', "B")
          }
          navigate("/Floor-Dashboard"); 
        } else {
          
          setLoginSuccess(false);
          console.error("Login failed");
        }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleNavigation = () => {
    if (loginSuccess) {
      navigate("/Floor-Dashboard");
    }
  };

  return (
    <div>
      <Header />
      <div className="text-center pt-3">
        <img src={Logo} alt="" />
      </div>
      <div className="container login">
        <div className="row">
          <div className="col-sm-12 col-lg-12 col-md-12 d-flex justify-content-center align-item-center">
            <div className="right-column">
              <h3
                style={{
                  color: "#FAA33D",
                  fontWeight: 600,
                  fontSize: "1.5rem",
                  fontFamily: "Montserrat",
                }}
              >
                <strong>Floor Login</strong>
              </h3>
              <form onSubmit={handleLogin}>
                <div className="input-container">
                  <input
                    type="text"
                    placeholder="Enter username"
                    name="username"
                    required
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className="input-container">
                  <input
                    type="password"
                    placeholder="Enter Password"
                    name="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                {loginSuccess === false ? (
                  <p style={{ color: "red" }}>Invalid username or password</p>
                ) : null}
                <button
                  type="submit"
                  onClick={handleNavigation}
                  className="login-button"
                >
                  LOGIN
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row pt-2">
          <div className="col-lg-12 col-md-12 col-sm-12 ps-2 pe-2 tdtl-logo d-flex justify-content-center">
            {/* Logo */}
            <a href="https://tdtl.world">
              <img src={tdtl_black} alt="Logo" />
            </a>
          </div>
          <div className="col-12">
            <div className="text-center pb-2">
              © 2024 MISSIONROJGAR. All rights reserved | Designed, Developed &
              Maintained by{" "}
              <a href="https://tdtl.world" style={{ textDecoration: "none" }}>
                The DataTech Labs Inc
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
