import React from "react";
import tdtl from "../Images/Logo_white.png";
import "../Styles/Footer.css";
// import iso1 from "../Images/ISO1.png"
// import iso2 from "../Images/ISO2.png"
// import iso3 from "../Images/ISO3.png"
import { IoCallSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";

const Footer = () => {
  return (
    <footer className="footer pt-3 pb-5">
      <div className="container">
        <div className="row ">
          <div className="col-lg-6 col-md-12 col-sm-12 py-3 tdtl-logo ">
            {/* Logo */}
            <a href="https://tdtl.world">
              <img src={tdtl} alt="Logo" />
            </a>
          </div>
          <div className="col-lg-3 col-md-12 col-sm-12 py-3 Footer-text">
            <div className="row">
              <div className="col-12">
                <div className="text-white">
                  <div className="text-white fs-5">
                    <strong>Quick Links</strong>
                    <div className="d-flex hr-tag">
                      <hr
                        style={{
                          width: "10rem",
                          backgroundColor: "white",
                          border: "none",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <a className="nav-link" href="https://tdtl.world/about-us">
                  About Us
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-12 col-sm-12 py-3 Footer-text">
            <div className="text-white fs-5">
              <strong>Contact Us</strong>
              <div className="d-flex hr-tag">
                <hr
                  style={{
                    width: "10rem",
                    backgroundColor: "white",
                    border: "none",
                  }}
                />
              </div>
            </div>
            <div>
              <a className="nav-link" href="tel:+918080762699">
                <IoCallSharp />
                +91 8080762699
              </a>
            </div>

            <a className="nav-link" href="mailto:apply@missionrojgar.com">
              <MdEmail /> apply@missionrojgar.com
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            
              <div className="text-white text-center pt-4 pb-2">
              © 2024 MISSIONROJGAR. All rights reserved | Designed, Developed & Maintained by <a href="https://tdtl.world" style={{ textDecoration: "none" }}>The DataTech Labs Inc</a>
              </div>
            
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
